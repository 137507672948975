import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img_2.png'
import bush from '../../image/AboutUs/bush.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='AboutUs'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.div  className={styles.box_image} variants={imageAnimation}>
                        <img  className={styles.image} src={image} alt='девушка' />
                        <div className={styles.box_profession}>
                            <h3 className={styles.subtitle}>Софья Дунаева</h3>
                            <p className={styles.profession}>brow и make up эксперт</p>
                        </div>
                    </motion.div>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Меня зовут Софья Дунаева, и уже более 3 лет я обучаю тому, как делать потрясающие брови, ресницы, макияж и хорошо зарабатывать на своей профессии.</h2>
                        <p className={styles.text}>В процессе обучения ты узнаешь какие инструменты использовать лучше, какие особенности физиологии волоса и кожи обязательно нужно знать, как находить клиентов на свои услуги и как начать зарабатывать от 5 000 рублей в день.</p>
                        <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                    </motion.div>
                    <img  className={styles.bush} src={bush} alt='кустик' />
            </div>
        </motion.section>
        
        
    )
}