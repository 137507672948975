import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Reviews/img.webp'



export const Feedback = () => {


    return (
        <section className={styles.main} id='Septic'>
            <div className={styles.box}>
                <h2 className={styles.title}>Отзывы наших учениц</h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Арина</p>
                                        <p className={styles.comment}>11.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>На днях завершился мой курс по наращиванию ресниц. У меня осталось море положительных впечатлений! Доброта, компетентность, внимательность, грамотная программа обучения, полезная информация, лёгкая атмосфера- это всё про моё обучение в данной студии! Я получила ответы на все мои вопросы, а также заряжена на работу мастером по наращиванию ресниц! В ближайшее время приду к вам на курс по бровям! Спасибо🙌😍💕 </p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Светлана</p>
                                        <p className={styles.comment}>11.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Изначально пришла на обучение по макияжу для себя, чтобы знать хотя бы азы. Но, так воодушевилась этим делом, что через месяц вернулась на полноценный курс визажист с 0. Спасибо🫶 вам за такие прекрасные курсы! Это то, что я искала🥰! Мои клиенты очень рады моим работам, а соответственно рада и я! Всем девочкам советую пройти обучение по макияжу, ведь это такая ценность🌸! </p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Екатерина</p>
                                        <p className={styles.comment}>05.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Долго искала куда пойти отучиться на мастера ногтевого сервиса, так как первый мой опыт с одной из студий, где я проходила курсы- был не удачный. И наконец-то нашла Вашу чудесную студию “Dunaeva Estudio”.🤗 Я Вам безмерно благодарна за те знания, которые Вы мне дали на курсе!❤️ Количество моделей приятно удивило, получилась мощная отработка пройденного материала. И очень удобно, что у Вас можно было приобрести обучение в рассрочку! Теперь я уверено чувствую себя в данной сфере благодаря Вашей программе курса и Вашему подходу!⭐️</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Марина</p>
                                        <p className={styles.comment}>02.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Вот и я стала мастером в сфере красоты, записалась на индивидуальный курс по наращиванию ресниц, пришла и получила большое количество знаний, которые были донесены элементарным языком (без лишней воды). На курсе была дружелюбная атмосфера💁‍♀️, с моделями было приятно работать, когда нуждалась в помощи, то получала её от преподавателя. Вообщем, осталась очень довольна после обучения! Буду применять знания на практике сейчас!!!🤗</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Елена</p>
                                        <p className={styles.comment}>28.01.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Хочу рассказать своё впечатление о курсе «Визажист с 0 до профи». 🙌 Идя на первое занятие очень переживала, ведь никогда не умела красить даже себя, но всегда горела желанием научиться, и спустя 10 занятий я стала уметь делать макияж любой сложности от дневного нюдового до яркого вечернего!🔥 Программа обучения очень сильная, видно, что вложен весь опыт работы в программу😊. Теперь буду работать и развиваться!☺️</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>           
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Виктория</p>
                                        <p className={styles.comment}>13.01.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Прошла курс маникюра и хочу ещё раз сказать огромное спасибо!🙏Такой ценной и полезной информации я ещё не получала нигде! На обучение были предоставлены все материалы и модели для практики👍. После обучения я вышла с теми знаниями, которые нужны для работы в этой сфере, а также с знаниями где искать первых клиентов.🙏 Рекомендую данное обучение всем тем, кто хочет стать профессиональным мастером маникюра! </p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Анастасия</p>
                                        <p className={styles.comment}>15.05.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Долго искала достойный курс и преподавателя по бровям и мне посчастливилось найти курс Софьи ✨ Всё, от начала и до конца, было четко, ясно и продумано. Все, что было заявлено в программе 2х дневного курса по бровям, было рассказано и показано. Я получила ответы на все возникшие вопросы, а так же мотивацию🙏🏻 Благодаря курсу, я начала работать с бровками в своем городе сразу же, как приехали материалы. Огромная благодарность Софье, курс 🔥</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Ольга</p>
                                        <p className={styles.comment}>14.04.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Хочешь кайфовать от своей работы, пожалуйста к Софе, это просто крутой мастер, преподаватель, все четко, ясно и по факту, мутной воды и левой/лишней информации здесь нет, только все что нужно знать. Изначально, я приходила в роди модели, в то же время горела сама отучиться, но не хотела прогадать, так-же я ходила и к другим мастерам моделью, из тех куда я ходила, в душу упала мне Софа. Что не мало важно, она предоставляет, фото/готовые рилсы, это означает, начало у тебя есть в ведении блога. В данный момент я кайфую от того что, я прошла этот курс, теперь я Brow-мастер, начало есть, остальное наверстаем😀</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>Евгения</p>
                                    <p className={styles.comment}>06.11.2022</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Проходила «полный Brow-курс» у Софьи, очень интересно и главное понятно что к чему. Все звезды сложились, чтобы я выбрала эту школу, и я ни капли не пожалела!!! Хочется проходить и проходить курсы именно в этой школе, потому-что я уже точно уверена, что здесь научат как четко и верно двигаться в выбивной сфере, а может даже и в нескольких, вы лучшие Estudio Dunaeva😻👍🏽👍🏽👍🏽</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Ирина</p>
                                        <p className={styles.comment}>26.04.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Прошла индивидуальный 3х дневный курс бровиста. Обучение прошло плодотворно. Софья - прекрасная добрая девушка, понятно все объясняет, помогает и контролирует процесс работы с моделями. Отвечает на вопросы, которые возникают в процессе обучения. Предоставляет все материалы для работы, а также моделей. Обучение проходило в красивой просторной студии. Сейчас я являюсь сертифицированным специалистом благодаря Софьи.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Елена</p>
                                        <p className={styles.comment}>28.03.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Курс обучения понравился, всё выдано как заявлено в описании курса, первый день теория, второй практика. Софья быстро и чётко отвечает на вопросы, помогает, но в то же время учит правильно ориентироваться самой в работе, что не мало важно. По теории было выдано всё чётко и ясно, далее применили это в практике под её чутким руководством. Модели были приглашены к назначеному времени, не надо было искать своих друзей и знакомых. Обучение скорректировано по удобству времени как обучающимся, так моделям и Софье. На первоначальном этапе информации более чем достаточно, планирую после обратиться к Софье за другими знаниями. Благодарю.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Эльвира</p>
                                        <p className={styles.comment}>12.02.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Всем доброго денёчка☺️ Вот у меня завершились 3 дня индивидуального обучения по бровям и ресницам у Софьи. Впечатления остались самые положительные. Софья оказалась очень приятной девушкой, с которой мы нашли общий язык. Была приятно удивлена, что такая молодая девушка преподаватель. Но Софья оказалась с опытом и с отличным багажом знаний по ресницам и бровям. Также было приятно, что не пришлось самой искать моделей, все модели были подобраны хорошо, было над чем работать. В общем всем рекомендую чудесную Софью в обучении, всё подробно объяснит, покажет и расскажет☺️🫶🏻🫶🏻🫶🏻</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Анастасия</p>
                                        <p className={styles.comment}>27.04.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Софья, хотела сказать спасибо за замечательный курс по бровям, все очень понравилось🥰 в первый день получила много полезной информации, а на второй день смогла самостоятельно поработать с моделями. Теперь буду применять эти знания на практике. Спасибо еще раз🌸</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}