import React from 'react'
import styles from './Instalment.module.scss'
import image from '../../image/Feedback/img.png'
import card from '../../image/Instalment/img.webp'

export const Instalment = ({isOpen}) => {

    return (
        <section className={styles.main}>
            <img className={styles.image} src={image} alt='фон'/>
            <div className={styles.box}>
                <div className={styles.box_info}>
                    <h2 className={styles.title}>Беспроцентная рассрочка на все программы обучения</h2>
                    <p className={styles.text}>Дорогие друзья, мы рады Вам сообщить, что любую из программ обучения нашей школы можно приобрести с помощью беспроцентной рассрочки, предоставляемой банком Тинькофф</p>
                    <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                </div>
                <img className={styles.card} src={card} alt='карточка'/>
            </div>
        </section>
        
    )
}