import React, {useState, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Services.module.scss'
import icon from '../../image/Services/icon.svg'
import icon_red from '../../image/Services/icon-red.svg'

const titleAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .8}
    } 
}


export const Services = ({isOpen}) => {

    const [isImage, setIsImege] = useState(1)
    const [isBackground, setIsBackground] = useState(1)

    const handleBackgroundOne = useCallback(() => {
        setIsBackground(1)
        setIsImege(1)
    }, []);

    const handleBackgroundTwo = useCallback(() => {
        setIsBackground(2)
        setIsImege(2)
    }, []);

    const handleBackgroundThree = useCallback(() => {
        setIsBackground(3)
        setIsImege(3)
    }, []);

    const handleBackgroundFour = useCallback(() => {
        setIsBackground(4)
        setIsImege(4)
    }, []);

    return (
        <section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <h2 className={styles.title}>Выбери свою программу</h2>
                    <motion.ul className={styles.list_link} variants={titleAnimation}>
                        <li className={`${isBackground === 1 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundOne}>брови
                        </li>
                        <li className={`${isBackground === 2 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundTwo}>ресницы</li>
                        <li className={`${isBackground === 3 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundThree}>макияж</li>
                        <li className={`${isBackground === 4 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundFour}>маникюр</li>
                    </motion.ul>
                    <motion.ul className={`${isImage === 1 ? styles.list : styles.list_none}`} variants={boxAnimation}>    
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Group</h3>
                                <p className={styles.item_subtitle}>продолжительность 2 дня</p>
                                <p className={styles.item_subtitle}>группа 2-3 человека</p>
                                <div className={styles.item_info}>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эскиз</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Работа с асимметрией и сложными бровями</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Структура кожи/волоса</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подготовка кожи и бровей к процедуре</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Красители</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Колористика</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Техники градиента</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Выкладка красителя</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Долговременная укладка бровей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция бровей (воск; нить; пинцет)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Процедура «Счастье для бровей»</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Этапы работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Стартовый набор (список)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Демонстрация работы преподавателем</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на трёх моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание портфолио</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>        
                                </div>
                                <p className={styles.price}>13 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Light</h3>
                                <p className={styles.item_subtitle}>продолжительность 1 день</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эскиз</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Работа с асимметрией и сложными бровями</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Структура кожи/волоса</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подготовка кожи и бровей к процедуре</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Красители</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Колористика</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Техники градиента</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Выкладка красителя</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Долговременная укладка бровей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция бровей (воск; нить; пинцет)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Процедура «Счастье для бровей»</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Этапы работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Стартовый набор (список)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на трёх моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание портфолио</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>        
                                </div>
                                <p className={styles.price}>10 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Standard</h3>
                                <p className={styles.item_subtitle}>продолжительность 2 дня</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эскиз</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Работа с асимметрией и сложными бровями</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Структура кожи/волоса</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подготовка кожи и бровей к процедуре</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Красители</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Колористика</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Техники градиента</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Выкладка красителя</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Долговременная укладка бровей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция бровей (воск; нить; пинцет)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Процедура «Счастье для бровей»</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Этапы работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Стартовый набор (список)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Демонстрация работы преподавателем</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на трёх моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание портфолио</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>        
                                </div>
                                <p className={styles.price}>16 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Premium</h3>
                                <p className={styles.item_subtitle}>продолжительность 3 дня</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эскиз</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Работа с асимметрией и сложными бровями</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Структура кожи/волоса</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подготовка кожи и бровей к процедуре</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Красители</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Колористика</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Техники градиента</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Выкладка красителя</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Долговременная укладка бровей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция бровей (воск; нить; пинцет)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Процедура «Счастье для бровей»</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Этапы работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Стартовый набор (список)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Демонстрация работы преподавателем</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на трёх моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание портфолио</p>
                                    </div>
                                    <p className={styles.item_subtitle}>3 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на трёх моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дополнение портфолио</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>        
                                </div>
                                <p className={styles.price}>21 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Exclusive</h3>
                                <p className={styles.item_subtitle}>продолжительность 4 дня</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эскиз</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Работа с асимметрией и сложными бровями</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Структура кожи/волоса</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подготовка кожи и бровей к процедуре</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Красители</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Колористика</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Техники градиента</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Выкладка красителя</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Долговременная укладка бровей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция бровей (воск; нить; пинцет)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Процедура «Счастье для бровей»</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Этапы работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Стартовый набор (список)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Демонстрация работы преподавателем</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на четырех моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание портфолио</p>
                                    </div>
                                    <p className={styles.item_subtitle}>3 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на четырех моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дополнение портфолио</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>
                                    <p className={styles.item_subtitle}>4 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание рабочей соц.сети</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Раскрутка себя, как brow-мастера</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Фотосессия</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Приложения, которые помогут в работе с соц.сетями</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Размещение первых публикаций с профессиональной точки зрения</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Фишки для продающих фото</p>
                                    </div>         
                                </div>
                                <p className={styles.price}>30 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    </motion.ul>
                    <motion.ul className={`${isImage === 2 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Классическое наращивание ресниц</h3>
                                <p className={styles.item_subtitle}>продолжительность 2 дня</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Анатомия ресниц. Стадия роста</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Всё об искусственных ресницах: изгиб, длина, толщина</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эффекты наращивания ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Типы глаз и их особенности</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Правила и условия работы с клеем</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Аллергия. Противопоказания к наращиванию ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Избежание аллергии на клей у мастера</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Правила идеального и чистого наращивания ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Снятие наращенных ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция ресниц. ЗА и ПРОТИВ</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Поэтапность работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Организация рабочего места</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Необходимые материалы для работы</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на манекене</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на 2 моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>            
                                </div>
                                <p className={styles.price}>15 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Классическое и обьемное наращивание ресниц</h3>
                                <p className={styles.item_subtitle}>продолжительность 3 дня</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Анатомия ресниц. Стадия роста</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Всё об искусственных ресницах: изгиб, длина, толщина</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Эффекты наращивания ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Типы глаз и их особенности</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Моделирование ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Правила и условия работы с клеем</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Аллергия. Противопоказания к наращиванию ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Избежание аллергии на клей у мастера</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Правила идеального и чистого наращивания ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Снятие наращенных ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция ресниц. ЗА и ПРОТИВ</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Поэтапность работы с клиентом</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Организация рабочего места</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Необходимые материалы для работы</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Объёмное наращивание ресниц (от 2D)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Формирование идеального пучка</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Мокрый эффект и лучики</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на манекене (классическое наращивание)</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на 1 модели (классическое наращивание)</p>
                                    </div>
                                    <p className={styles.item_subtitle}>3 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на манекене (объёмное наращивание)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на 1 модели (объёмное наращивание)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>            
                                </div>
                                <p className={styles.price}>20 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Ламинирование ресниц</h3>
                                <p className={styles.item_subtitle}>продолжительность 1 день</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Знакомство с процедурой</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Строение ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Обзор материалов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подбор валика</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Выкладка и натяжка ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Противопоказания</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Этапы работы</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Протеиновое восстановление ресниц</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дезинфекция инструментов</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Подарочный блок «Ботокс ресниц» и «Вельвет ресниц»</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Демонстрация работы преподавателем</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на одной модели</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>           
                                </div>
                                <p className={styles.price}>10 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    </motion.ul>
                    <motion.ul className={`${isImage === 3 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Визажист с 0 до профи</h3>
                                <p className={styles.item_subtitle}>продолжительность 1 месяц (10&#160;занятий)</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Знакомство. Вводная часть</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Дневной макияж</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Вечерний макияж</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Голливудский макияж</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Классический Smoky Eye</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Чёрный Smoky Eyes</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Свадебный макияж</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Цветной макияж</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Лифтинг макияж</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на 9 моделях</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор ошибок</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Создание фото/видео(reels)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Основы продвижения</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Подарочный блок «Созданию лёгких локонов»</p>
                                    </div>            
                                </div>
                                <p className={styles.price}>40 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Макияж для себя</h3>
                                <p className={styles.item_subtitle}>продолжительность 1 день (4-5&#160;часов)</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Знакомство с кистями (Функции кистей)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Нанесение тона (Подготовка кожи перед макияжем. Важность данного этапа. Подбор тонального средства для разных типов кожи)</p>
                                    </div> 
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Оформление бровей (Карандаши, помадки, тени- на Ваш выбор, проговорим про всё)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Оформление глаз (Выразительные глаза. Чем нужно дополнить дневной макияж? Азы мягкой тушёвки теней, переход из одного оттенка в другой)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Коррекция лица (Знакомство с сухими и кремовыми продуктами. Почему важно подобрать подходящий для Вашей кожи продукт?)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Оформление губ (Секреты чётких и выразительных губ, без подтёкшей помады или карандаша)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор Вашей косметички (Если у Вас есть желание, то прямо на курсе мы сделаем разбор Вашей косметички, и вместе проанализируем ее содержимое)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка дневного макияжа на себе (Под чутким контролем преподавателя-визажиста)</p>
                                    </div>
                                </div>
                                <p className={styles.price}>10 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    </motion.ul>
                    <motion.ul className={`${isImage === 4 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                    <li className={styles.item}>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Курс маникюра с 0</h3>
                                <p className={styles.item_subtitle}>продолжительность 4 дня</p>
                                <p className={styles.item_subtitle}>индивидуально</p>
                                <div className={styles.item_info}>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Стерилизация по СанПиН</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Средства и правила обработки инструмента, кожи, поверхностей. Личная гигиена мастера</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Разбор инструментов и материалов для работы (фрезы, режущий инструмент, аппарат, базы, топы, гели)</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Строение и типы ногтей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Строение и типы кожи рук</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Заболевания рук и ногтей</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Обработка кутикулы, опил длины и формы</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Работа с базами, гелями, топами. Техника выравнивания ногтевой пластины, укрепление гелем, акриловой пудрой, тонкие торцы</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Техника снятия покрытия</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Экспресс дизайны: втирка, фольга, поталь, растяжка, слайдеры, френч</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Организация рабочего места</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Имидж мастера маникюра</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на 6 моделях</p>
                                    </div>
                                    <p className={styles.item_subtitle}>1 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Tеория + отработка на себе</p>
                                    </div>
                                    <p className={styles.item_subtitle}>2 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на двух моделях(маникюр + покрытие гель-лак (нюд), маникюр + покрытие гель-лак (цвет))</p>
                                    </div>
                                    <p className={styles.item_subtitle}>3 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на двух моделях(маникюр + укрепление + френч, маникюр + укрепление + покрытие гель-лак (цвет))</p>
                                    </div>
                                    <p className={styles.item_subtitle}>4 день</p>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Отработка на модели (маникюр + покрытие гель-лак + дизайн).</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon} alt='плюсик'/>
                                        <p className={styles.item_text}>Экзамен</p>
                                    </div>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={icon_red} alt='плюсик'/>
                                        <p className={styles.item_text}>Выдача сертификата</p>
                                    </div>            
                                </div>
                                <p className={styles.price}>25 000 ₽</p>
                                <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                            </div>
                    </li>
                    </motion.ul>       
                </div>
        </section>
        
    )
}