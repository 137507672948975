import React from 'react'
import styles from './Skills.module.scss'
import { motion } from "framer-motion"
import image from '../../image/Feedback/img.png'
import img from '../../image/Skills/img.png'
import foto from '../../image/Skills/image.png'
import ribbon from '../../image/Skills/ribbon.png'
import icon from '../../image/Skills/circle.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Skills = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <img className={styles.image} src={image} alt='фон'/>
                <img className={styles.ribbon} src={ribbon} alt='фон'/>
                <div className={styles.box} >
                    <motion.h2 className={styles.title} variants={titleAnimation}>Что ты получишь на курсе</motion.h2>
                    <motion.div className={styles.box_info} variants={boxAnimation}>
                        <div className={styles.box_skills}>
                            <div className={styles.skills}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={icon} alt='плюсик'/>
                                    <p className={styles.item_text}>Востребованную профессию</p>
                                </div>
                            </div>
                            <div className={styles.skills}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={icon} alt='плюсик'/>
                                    <p className={styles.item_text}>Навыки востребованного мастера</p>
                                </div>
                            </div>
                            <div className={styles.skills}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={icon} alt='плюсик'/>
                                    <p className={styles.item_text}>Умение продавать свои услуги</p>
                                </div>
                            </div>
                            <div className={styles.skills}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={icon} alt='плюсик'/>
                                    <p className={styles.item_text}>Портфолио ваших работ</p>
                                </div>
                            </div>
                            <div className={styles.skills}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={icon} alt='плюсик'/>
                                    <p className={styles.item_text}>Сертификат мастера</p>
                                </div>
                            </div>
                            <div className={styles.skills}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={icon} alt='плюсик'/>
                                    <p className={styles.item_text}>Сопровождение преподавателя</p>
                                </div>
                            </div>
                        </div>
                        <img className={styles.foto} src={foto} alt='девушка'/>
                        <div className={styles.item_one}>
                            <p className={styles.text}>Востребованную профессию</p>
                        </div>
                        <div className={styles.item_two}>
                            <p className={styles.text}>Навыки востребованного мастера</p>
                        </div>
                        <div className={styles.item_three}>
                            <p className={styles.text}>Портфолио ваших работ</p>
                        </div>
                        <div className={styles.item_four}>
                            <p className={styles.text}>Сертификат мастера</p>
                        </div>
                        <div className={styles.item_five}>
                            <p className={styles.text}>Сопровождение преподавателя</p>
                        </div>
                        <div className={styles.item_six}>
                            <p className={styles.text}>Умение продавать свои услуги</p>
                        </div>  
                        <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                    </motion.div>
                </div>
                <img className={styles.img} src={img} alt='фон'/>
        </motion.section>
        
    )
}