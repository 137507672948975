import React from 'react'
import header from './Header.module.scss'

export const Header = ({isOpen}) => {

    return (
        <header className={header.header}>
       
    </header>    
    );
};