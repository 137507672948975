import React from 'react'
import styles from './Main.module.scss'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import { InitialPage } from '../../components/initialPage/InitialPage'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Services } from '../../components/Services/Services'
import icon from '../../image/Header/logo.png'
import {Discount} from '../../components/Discount/Discount'
import { Feedback } from '../../components/Feedback/Feedback'
import { Instalment } from '../../components/Instalment/Instalment'
import {Works} from '../../components/Works/Works'
import { Skills } from '../../components/Skills/Skills'
import { Route } from '../../components/Route/Route'

export const Main = ({isImageOpen, popupOpen, isOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <AboutUs
                isOpen={isOpen}
            />
            <Skills
                isOpen={isOpen}
            />
            <Services
                isOpen={isOpen}
            />
            <Instalment
                isOpen={isOpen}
            />
            <Feedback/>
            <Works/>
            <Discount
                popupOpen={popupOpen}
            />
            <div className={styles.box_map}>
                    <YMaps>
                        <Map width='100%' height='100%' defaultState={{ center: [55.735099, 38.005725], zoom: 13}}>
                            <Placemark 
                                geometry={[55.735099, 38.005725]} 
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageSize: [40, 40],
                                        iconImageHref: icon,
                                        }}
                                    />
                        </Map>
                    </YMaps>
            </div>
            <Route/>
        </section>
    )
}