import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"
import image from '../../image/InitialPage/img_1.webp'
import logo from '../../image/Header/logo.svg'
import logo_1 from '../../image/Header/logo.png'


export const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                    <div className={styles.item}>
                        <img className={styles.image} src={image} alt='девушка'/>
                        <div className={styles.overlay}>
                            <div className={styles.box}>
                                <div className={styles.container}>
                                <img className={styles.logo} src={logo} alt = 'логотип'/>
                                <img className={styles.logo_min} src={logo_1} alt = 'логотип'/>
                                    <h1 className={styles.title}>Научим делать мир&#160;прекраснее</h1>
                                    <p className={styles.subtitle}>и выведем на доход  от&#160;5&#160;000&#160;рублей в день</p>
                                    <p className={styles.text} >Обучаем от нуля до мастера своего дела. После обучение <span>мы выдаем сертификат</span></p>
                                    <button className={styles.button} type='button' onClick={isOpen}>Записаться на курс</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
        </motion.section>
    )
}