import React, {useState, useCallback} from 'react'
import styles from './Works.module.scss'
import { motion } from "framer-motion"
import { v4 as uuidv4 } from 'uuid'
import { arrWorks } from '../../utils/data'
import { arrEyelash } from '../../utils/data'
import { arrManicure } from '../../utils/data'
import { arrMakeup } from '../../utils/data'
import image from '../../image/Feedback/img.png'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}


export const Works = () => {

    const [isImage, setIsImege] = useState(1)
    const [isBackground, setIsBackground] = useState(1)
    const [isArr, setIsArr] = useState(arrWorks)

    const handleBackgroundOne = useCallback(() => {
        setIsBackground(1)
        setIsImege(1)
        setIsArr(arrWorks)
    }, []);

    const handleBackgroundTwo = useCallback(() => {
        setIsBackground(2)
        setIsImege(2)
        setIsArr(arrEyelash)
    }, []);

    const handleBackgroundThree = useCallback(() => {
        setIsBackground(3)
        setIsImege(3)
        setIsArr(arrMakeup)
    }, []);

    const handleBackgroundFour = useCallback(() => {
        setIsBackground(4)
        setIsImege(4)
        setIsArr(arrManicure)
    }, []);

        const [worksTotal, setWorksTotal] = useState(3)
    
        const addCards = useCallback(() => {
            setWorksTotal(worksTotal + 1);
        }, [worksTotal])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <img className={styles.img} src={image} alt='фон'/>
            <div className={styles.box}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Работы выпускниц</motion.h2>
            <motion.ul className={styles.list_link} variants={titleAnimation}>
                        <li className={`${isBackground === 1 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundOne}>брови
                        </li>
                        <li className={`${isBackground === 2 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundTwo}>ресницы</li>
                        <li className={`${isBackground === 3 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundThree}>макияж</li>
                        <li className={`${isBackground === 4 ? styles.item_blue : styles.item_link}`} onClick={handleBackgroundFour}>маникюр</li>
            </motion.ul>
                <motion.ul className={`${isImage === 1 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                {arrWorks.map((item, index) => {
                        const keyUid = uuidv4()
                            if (index + 1 <= worksTotal) {
                                return(
                                    <li className={styles.item} key={keyUid}>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.img} alt='девушка' />
                                            <p className={styles.text}>до</p>
                                        </div>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.image} alt='девушка' />
                                            <p className={styles.text}>после</p>
                                        </div>
                                    </li>
                                )
                            } else {
                                return ''
                            }
                    })}       
                </motion.ul>
                <motion.ul className={`${isImage === 2 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                {arrEyelash.map((item, index) => {
                        const keyUid = uuidv4()
                            if (index + 1 <= worksTotal) {
                                return(
                                    <li className={styles.item} key={keyUid}>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.img} alt='девушка' />
                                            <p className={styles.text}>до</p>
                                        </div>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.image} alt='девушка' />
                                            <p className={styles.text}>после</p>
                                        </div>
                                    </li>
                                )
                            } else {
                                return ''
                            }
                    })}       
                </motion.ul>
                <motion.ul className={`${isImage === 3 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                {arrMakeup.map((item, index) => {
                        const keyUid = uuidv4()
                            if (index + 1 <= worksTotal) {
                                return(
                                    <li className={styles.item} key={keyUid}>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.img} alt='девушка' />
                                            <p className={styles.text}>до</p>
                                        </div>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.image} alt='девушка' />
                                            <p className={styles.text}>после</p>
                                        </div>
                                    </li>
                                )
                            } else {
                                return ''
                            }
                    })}       
                </motion.ul>
                <motion.ul className={`${isImage === 4 ? styles.list : styles.list_none}`} variants={boxAnimation}>
                {arrManicure.map((item, index) => {
                        const keyUid = uuidv4()
                            if (index + 1 <= worksTotal) {
                                return(
                                    <li className={styles.item} key={keyUid}>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.img} alt='девушка' />
                                            <p className={styles.text}>до</p>
                                        </div>
                                        <div className={styles.cell}>
                                            <img  className={styles.image} src={item.image} alt='девушка' />
                                            <p className={styles.text}>после</p>
                                        </div>
                                    </li>
                                )
                            } else {
                                return ''
                            }
                    })}       
                </motion.ul>
                {worksTotal < isArr.length && <button  className={styles.button} onClick={addCards}>Ещё работы</button>}
            </div>
            <img className={styles.img_bottom} src={image} alt='фон'/>
        </motion.section>
        
    )
}