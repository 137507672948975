import React from 'react'
import styles from './Route.module.scss'


export const Route = () => {

    return (
        <section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div  className={styles.box}>
                    <div className={styles.item}>
                        <h2 className={styles.title}>Как до нас добраться</h2>
                        <p className={styles.text}>Наша школа расположена по адресу: <span>Московская область, г.&#160;Балашиха, мкр.&#160;Железнодорожный, ул.&#160;Корнилаева&#160;28.</span></p>
                        <p className={styles.text}>Если Вы добираетесь общественным транспортом, то от станции  «МЦД Железнодорожная», до нашей школы, можно добраться пешком, или доехать на маршрутке 34К, необходимо проехать  всего 5 остановок до остановки «ЖК Центр-2», далее ровно 219 метров до входной группы. Как правило дорога в обоих случаях занимает не более 10-15 минут.  Приезжайте, мы Вас ждем!</p>
                    </div>
                </div>
                
        </section>
        
    )
}