import img_1 from '../image/Works/img_1_1.webp'
import img_2 from '../image/Works/img_1_2.webp'
import img_3 from '../image/Works/img_2_1.webp'
import img_4 from '../image/Works/img_2_2.webp'
import img_5 from '../image/Works/img_3_1.webp'
import img_6 from '../image/Works/img_3_2.webp'
import img_7 from '../image/Works/img_4_1.webp'
import img_8 from '../image/Works/img_4_2.webp'
import img_9 from '../image/Works/img_5_1.webp'
import img_10 from '../image/Works/img_5_2.webp'

import eye_1 from '../image/Works/Eyelash/img_1_1.webp'
import eye_2 from '../image/Works/Eyelash/img_1_2.webp'
import eye_3 from '../image/Works/Eyelash/img_2_1.webp'
import eye_4 from '../image/Works/Eyelash/img_2_2.webp'
import eye_5 from '../image/Works/Eyelash/img_3_1.webp'
import eye_6 from '../image/Works/Eyelash/img_3_2.webp'
import eye_7 from '../image/Works/Eyelash/img_4_1.webp'
import eye_8 from '../image/Works/Eyelash/img_4_2.webp'
import eye_9 from '../image/Works/Eyelash/img_5_1.webp'
import eye_10 from '../image/Works/Eyelash/img_5_2.webp'

import man_1 from '../image/Works/Manicure/img_1_1.webp'
import man_2 from '../image/Works/Manicure/img_1_2.webp'
import man_3 from '../image/Works/Manicure/img_2_1.webp'
import man_4 from '../image/Works/Manicure/img_2_2.webp'
import man_5 from '../image/Works/Manicure/img_3_1.webp'
import man_6 from '../image/Works/Manicure/img_3_2.webp'
import man_7 from '../image/Works/Manicure/img_4_1.webp'
import man_8 from '../image/Works/Manicure/img_4_2.webp'
import man_9 from '../image/Works/Manicure/img_5_1.webp'
import man_10 from '../image/Works/Manicure/img_5_2.webp'

import mak_1 from '../image/Works/Makeup/img_1_1.webp'
import mak_2 from '../image/Works/Makeup/img_1_2.webp'
import mak_3 from '../image/Works/Makeup/img_2_1.webp'
import mak_4 from '../image/Works/Makeup/img_2_2.webp'
import mak_5 from '../image/Works/Makeup/img_3_1.webp'
import mak_6 from '../image/Works/Makeup/img_3_2.webp'
import mak_7 from '../image/Works/Makeup/img_4_1.webp'
import mak_8 from '../image/Works/Makeup/img_4_2.webp'
import mak_9 from '../image/Works/Makeup/img_5_1.webp'
import mak_10 from '../image/Works/Makeup/img_5_2.webp'


export const arrWorks = [{ img: img_1, image: img_2,}, { img: img_3, image: img_4,}, { img: img_5, image: img_6,}, { img: img_7, image: img_8,}, { img: img_9, image: img_10,},]

export const arrEyelash = [{ img: eye_9, image: eye_10,}, { img: eye_7, image: eye_8,}, { img: eye_1, image: eye_2,}, { img: eye_3, image: eye_4,}, { img: eye_5, image: eye_6,},]

export const arrManicure = [{ img: man_9, image: man_10,}, { img: man_7, image: man_8,}, { img: man_1, image: man_2,}, { img: man_3, image: man_4,}, { img: man_5, image: man_6,},]

export const arrMakeup = [{ img: mak_9, image: mak_10,}, { img: mak_7, image: mak_8,}, { img: mak_1, image: mak_2,}, { img: mak_3, image: mak_4,}, { img: mak_5, image: mak_6,},]